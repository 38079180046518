import "bootstrap/dist/js/bootstrap.min.js";
import "@popperjs/core/dist/umd/popper.min.js";
import "./src/styles/fontawesome/css/fontawesome.min.css"
import "./src/styles/fontawesome/css/thin.min.css"
import "./src/styles/fontawesome/css/brands.min.css"
import "./src/styles/fontawesome/css/sharp-solid.min.css"

import React from "react";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";

export const wrapRootElement = ({ element }) => {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.GATSBY_RECAPTCHA_SITE_KEY}
    >
      {element}
    </GoogleReCaptchaProvider>
  );
};
